import React from 'react';
import ReactStringReplace from 'react-string-replace';
import COLORS from '../res/colors';
//ext lib for lighliting text - https://www.npmjs.com/package/react-string-replace?activeTab=readme
//react-string-replace

const Hashtag_colorful = ({ text,onHashtagClick,onMentionClick }) => {
  // Replace hashtags in the given text
  let replacedText = ReactStringReplace(text, /#(\w+)/g, (match, i) => (
    <span key={`hashtag-${i}`}
     style={{ color: COLORS.bk_gd,cursor: 'pointer' }}
     onClick={() => onHashtagClick(match)}>
      #{match}
    </span>
  ));

   // Replace words starting with @
   replacedText = ReactStringReplace(replacedText, /@(\w+)/g, (match, i) => (
    <span
      key={`mention-${i}`}
      style={{ color: COLORS.blue, fontWeight: 'bold', cursor: 'pointer' }}
      onClick={() => onMentionClick(match)}
    >
      @{match}
    </span>
  ));

  return <div>{replacedText}</div>;
};

export default Hashtag_colorful;
