import { Avatar, Card, CardActions, CardContent, CardHeader, CardMedia, Container, Typography,Grid, Button } from "@mui/material";
import React,{useState,useEffect, Suspense} from "react";
import firebase from '../firebase';
import COLORS from '../res/colors';
import Hashtag_colorful from "./Hashtag_colorful";
import LandingNew from "./LandingNew";
import {postwa,langwa, userwa,clickeduidwa,clickeduidbuddyidwa,clickeduidnamewa,clickeduidprof_picwa,clickedstoryidwa, clickedhtagwa, previewImagewa, urlForOnepostwa} from "../PostStore";
import {useAtom} from "jotai"
import { useParams } from 'react-router-dom';
import GetBandhooApp from "./GetBandhooApp";
import { db } from '../firebase';
import { collection, getDocs, query, where, orderBy, limit,doc,getDoc,onSnapshot, updateDoc,increment} from "firebase/firestore"; 
import { getAuth, onAuthStateChanged } from "firebase/auth";
import UsersNew from './UsersNew';
import StoryList from "./StoryList";
import LikeComment from "./Like_Comment";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Helmet } from 'react-helmet';


   //https://www.npmjs.com/package/react-hashtag

 
export default function PcscreenNew(){
    const[post,setPost] = useAtom(postwa);
    const [clickeduid,setClickeduid] = useAtom(clickeduidwa);//uid where clicked
    const[clickeduidname,setClickeduidname] = useAtom(clickeduidnamewa);
    const[clickeduidbuddyid,setClickeduidbuddyid] = useAtom(clickeduidbuddyidwa);
    const[clickeduidprof_pic,setClickeduidprof_pic] = useAtom(clickeduidprof_picwa);
    const[lang,setLang] = useAtom(langwa);
    const[user,setUser] = useAtom(userwa);//logged in user
    const [height,setHeight] = useState(window.innerHeight);
    const[clickedstoryid,setClickedstoryid] = useAtom(clickedstoryidwa);
    const[previewImage,setPreviewImage] = useAtom(previewImagewa);
    const[urlForOnepost,setUrlForOnepost] = useAtom(urlForOnepostwa);
   
   
 
    let { ct } = useParams(); //ct coming from dynamic pat of url hashtgs/ct. See in Routes.js
    let {postkey} = useParams();
    let {usr} = useParams();
    let tryk = 'https://firebasestorage.googleapis.com/v0/b/bandhoo-ximages/o/xpress_image%2Frathore%2FrathoreB8qbMjJjPq5mOgcZ62Q6?alt=media&token=5e46b8d0-8e5e-4272-bd06-c87c3afd4959'
    let trykk = 'https://bandhoo.in/onepost/B8qbMjJjPq5mOgcZ62Q6' 
    //console.log(clickeduid);

    //to track height of screen. for max height of video.
    useEffect(() => {
        const handleWindowResize = () => {
          setHeight(window.innerHeight);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);


    useEffect(()=>{
        const subs = onAuthStateChanged(getAuth(),(user)=>{
            if (user) { setUser(user);
                clickeduid ? postOfUser(clickeduid,clickeduidname,clickeduidbuddyid,clickeduidprof_pic)
                : ct       ? postOfTag(ct)
                :postkey    ? postOfPostkey(postkey,user)
                :usr        ? postOfUser1(usr)
                           :  postOfLoggedUser(user);
                        }
             else {
             ct          ? postOfTag(ct)
             :postkey    ? postOfPostkey(postkey,user)
             :usr        ? postOfUser1(usr)
             :             fetchData()
           }

        });
        return () => { subs();};
    },[])
   

   

    const postOfLoggedUser = async (user) =>{ 
        let keylist =[];
        const pttostart =0;
        let pttoend =9;
        const docref = doc(db,"users_wall",user.uid);
        let docSnap = await getDoc(docref);
        if(docSnap.exists){
             keylist=Array.from( docSnap.data().postkey);
             keylist.reverse();
             //console.log(keylist.length);
            
            if(keylist.length <10){ pttoend=keylist.length;}
            else{  pttoend=9;}
            let qq = query(collection(db,"xpression"),where("postkey", "in",keylist.slice(pttostart,pttoend)),orderBy("time", "desc"));
            // const querySnapshot = await getDocs(qq);
            // let items=querySnapshot.docs.map((doc)=>doc.data());
            // setPost(items);


            //below for real time listening
            const unsub = onSnapshot(qq,(snp)=>{
                const arraydata = [];
                snp.forEach((doc)=>{arraydata.push(doc.data())});
                setPost(arraydata);

            })
        }
    }

    const fetchData = async (e) =>{
        let q = query(collection(db,"xpress_nologin"), where("langg","==",lang),orderBy("time",'desc'),limit(60));
        const querySnapshot = await getDocs(q);
        let items=querySnapshot.docs.map(doc=>doc.data());
        setPost(items);
    }

   const postOfUser1 =async(buddyid)=>{
    const q = query(collection(db,"xpression"), where("buddyid","==",buddyid),orderBy("time",'desc'),limit(50));
    const querySnapshot = await getDocs(q);
    let items=querySnapshot.docs.map(doc=>doc.data());
    setPost(items);
   }

   const postOfUser = async(uid,name,buddyid,prof_pic)=>{
    setClickeduid(uid);
    setClickeduidname(name);
    setClickeduidbuddyid(buddyid);
    setClickeduidprof_pic(prof_pic);

    const q = query(collection(db,"xpression"), where("uid","==",uid),orderBy("time",'desc'),limit(50));
    const querySnapshot = await getDocs(q);
    let items=querySnapshot.docs.map(doc=>doc.data());
    setPost(items);
    }


    const postOfTag =async (htag)=>{
        setClickeduid('');
        setClickeduidbuddyid('');
        const q = query(collection(db,"xpression"), where("htags","array-contains",htag),orderBy("time",'desc'),limit(50));
        const querySnapshot = await getDocs(q);
        let items=querySnapshot.docs.map(doc=>doc.data());
        setPost(items);

    }

    const postOfPostkey = async(postkey,user)=>{
        const q = query(collection(db,"xpression"), where("postkey","==",postkey));
        const querySnapshot = await getDocs(q);
        let items=querySnapshot.docs.map(doc=>doc.data());
        setPost(items);
        setPreviewImage(items[0].image);//for preview when sharing the link
        setUrlForOnepost("https://bandhoo.in/onepost/"+items[0].postkey);

        if(user){ const  CollRef1 = doc(db,'xpression',postkey);  updateDoc(CollRef1,{views:increment(1)});}
        else{
          // as user is not logged in. So started below calleable function ....increaseViews
          //some error coming !!
          const functionn = getFunctions();
          var increaseViews = httpsCallable(functionn,'increaseViews');
          increaseViews({key: postkey}); }

          
    }

   

   

    const calculateTime=(time)=>{
        var diff= Math.abs(new Date(time).getTime() - new Date().getTime());
        var ddiff;
        var hdiff;
        var mdiff;
        ddiff=Math.floor(diff/(1000*60*60*24));
        diff -= ddiff*1000*60*60*24;
     
        hdiff=Math.floor(diff/(1000*60*60));
        diff -= hdiff*1000*60*60;
     
        mdiff=Math.floor(diff/(1000*60));
        diff -= mdiff*1000*60;
        if(ddiff >= 1)
        {
        return String(ddiff)+'d '+String(hdiff)+'h '+String(mdiff)+'m';
        }
        else if (hdiff >=1){ return String(hdiff)+'h '+String(mdiff)+'m';}
     
        else {return String(mdiff)+'m';}    
       }

    

    return(
        
        <>
        <Helmet>
             <meta property="og:title" content="Bandhoo" />
            <meta property="og:description" content="Our Bandhoo" />
            <meta property="type" content="website"/>
            <meta property="og:image" content= {previewImage} />
            <meta property="og:url" content={urlForOnepost}/>
            { console.log('ppp',previewImage)}
             {console.log('kkk',urlForOnepost)}
        </Helmet>
        <Grid container   direction="row"  marginTop={1} >
            <Grid item xs={2} >  
                <LandingNew />                
            </Grid>

            <Grid item xs={7}>
       
        <Container maxWidth="md">
        {
            clickeduid ?(
                <Grid key={"id"} Container spacing={2} margin={1}>
                    <Grid item>
                         <p>{clickeduidbuddyid}</p>
                    </Grid>
                    <Grid item container spacing={2} direction="row">
                        <Grid item>
                            <Avatar src={clickeduidprof_pic}  sx={{width:75,height:75,border: '1px solid',borderColor:COLORS.bk_gd}}/>
                        </Grid>
                        <Grid item>
                            <p></p>
                        </Grid>
                        <Grid item>
                            <p></p>
                        </Grid>
                     </Grid>
                     <Grid item>
                         <p >{clickeduidname}</p>
                     </Grid>
            </Grid>
            )
            :
            ( user ? <StoryList/> : null)
        }
        </Container>
        
        <Container maxWidth="md"  >          
             {
                 post.map((data) =>(
                    <Card key={data.postkey} sx={{mb:0.5}}>
                        <CardHeader  onClick={()=>{postOfUser(data.uid,data.name,data.buddyid,data.prof_pic)}}
                             avatar={
                                <Avatar src={data.prof_pic} sx={{width:50,height:50,border: '1px solid',borderColor:COLORS.bk_gd}} />
                                    }

                              action={
                                <Button>Follow</Button>
                              }      
                             title={<Typography fontSize={15} color={COLORS.blue}>{data.buddyid}</Typography>}
                            subheader={calculateTime(data.time)}
                        />

                        <CardContent >
                            <Typography  fontSize={15}>          
                               <Hashtag_colorful text={data.msg}
                                onHashtagClick={(htag)=>postOfTag(htag.substring(1))}
                                onMentionClick={(bid)=>{postOfUser1(bid)}}/>             
                           </Typography>
                         </CardContent>

                        <p>
                           {data.video ?
                            <CardMedia  component="video" sx={{objectFit:'contain',maxHeight:height}} src={data.video} controls />
                             : data.image ?
                             <CardMedia  component="img" src={data.image}/>
                             :null}
                        </p>

                        <CardActions >
                            <Suspense>
                                <LikeComment data={data} pkey ={data.postkey}/>
                            </Suspense>
                           {/* //tried using Suspense for better performance as LikeComment was using
                           Promise for conditional rendering of like or liked. but it is working without Suspense also ! */}
                        </CardActions>
                    </Card>
                   ))                         

            }
           
        </Container>
        </Grid>

        <Grid  item xs={3} >
          
           {user ? <UsersNew/> :  <GetBandhooApp/>}
        </Grid>
       

        </Grid>
        </>
        
    )
}
