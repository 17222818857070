import React , {Component} from 'react';
export class Privacy_policy extends React.Component {
  
    constructor(){
      super();
    
    }
    componentDidMount(){}
    render(){
        return(
            <div style={{width:'100%',display:'flex',flexDirection:'column',marginLeft:'10px',marginRight:'10px'}}>
            <h2>  Bandhoo Privacy Policy </h2>

            <h4> General guidelines </h4>
            <p>
                1. Name and Email- Users provide us information regarding their name,hobby, mobile number, date of birth and email address for setting up
                 their account. We do not share this information with anyone. 2. It is optional for user to share either his
                 mobile no or email . 3. Users may send/ receive text, image messages to other users and in groups. User has been
                  given power to delete his post anytime. Once deleted, it will be deleted not only from the user to whom 
                  he has shared his post , but also from the server. Hence, it is the choice of the user that how long he 
                  wants his message to remain in server. 4. Customer Support. Users may provide us with information related
                   to your use of our Services, including copies of your messages, and how to contact you so we can provide
                    you customer support. Likewise, users may send us an email with information relating to our app 
                    performance or other issues. 5. We do not collect information about your SMS, device, or call
                     log. 6. Status Information - We collect information about your status. We display it to others as if you 
                     are “online “ or not.  We may also show information like when you used our service last time or 
                     when you were “online” last time. 7. Third party involvement- we may use third party like Google 
                     play/Firebase/Firestore to get feedback / analysis about our app. We may use Firebase and Firestore for storage of data till
                      it is not deleted by the user. Please note that when you use third-party services, their own terms 
                      and privacy policies will govern your use of those services. 8. No Third-Party Banner Ads- We do not
                      allow third-party banner ads on Bandhoo. but we may introduce this in future. It will be shown without
                      compromising the user experience. 9. Users with whom you communicate may store or share your information
                      (including your phone number or messages or images) with others. 10. We may collect, use, preserve, and
                      share your information if we have a good-faith belief that it is reasonably necessary to: (a) respond 
                      pursuant to applicable law or regulations, to legal process, or to government requests; (b) enforce 
                      our Terms and any other applicable terms and policies, including for investigations of potential
                      violations; (c) detect, investigate, prevent, and address fraud and other illegal activity, security,
                      or technical issues; or (d) protect the rights, property, and safety of our users or others. We will
                      keep on updating our private policy as and whenever we find it necessary. We will inform you about 
                      such amendments. Write to us – bandhoo_feedback@yahoo.com or bandhoo.official@yahoo.com 
            </p>

            <h4>Information we collect</h4>
            <p>It is our endeavour to provide users the best possible experience with minimum information collection. However
               following information is collected from users when one wishes to use Bandhoo platform -</p>
            <p>
              i. Name - For profile creation
              </p><p>
              ii. Mobile no or email - For authentication and identification
              </p><p>
              iii. Location - For better post serving 
              </p><p>
              iv. Hobby - For better post serving 
              </p><p>
              v. Language - For better connectivity and post serving
              </p><p>
              vi. Profile picture - For profile making
              </p><p>
                vii. Identity paper - For verification of user. Submission of identity paper is purily voluntary.
              </p><p>
              viii. About self - For profile creation.
              </p><p>ix. Date of birth - To see eligibility of joining Bandhoo</p>

            <h4 >User Generated Content (UGC)</h4>
            <p> This comprises all of the information you make available to other users via Platform, like any post including
              video, image, text, GIFs etc or any information about you or relating to you that is voluntarily shared by you on the Bandhoo app,
               including without limitation, any quotes, images, political opinions, religious views, etc. This includes, but is not limited to: user profiles, 
               comments, media, posts, etc.  If you are found to have committed any of the actions listed below appropriate action might be
                taken against you including, but not limited to, taking down the violative content, in the appropriate circumstances 
                informing the authorities, and suspension or termination of your account:
               </p> 
               <p>
               1. SEXUAL CONTENT AND PROFANITY - No user should post any content that is  that contain or promote sexual content or profanity, including pornography, or any content or services intended to be sexually gratifying.
               </p> 
               <p>
               2. HATE SPEECH - No user should post any content that promote violence, or incite hatred against individuals or groups based on race or ethnic origin, religion, disability, age, nationality, veteran status, sexual orientation, gender, gender identity, or any other characteristic that is associated with systemic discrimination or marginalization.
               </p> 
               <p>
               3. VIOENCE -  No user should post any content that depict or facilitate gratuitous violence or other dangerous activities.
               </p> 
               <p>
               4. TERRORIST CONTENT -  No user should post any content related to terrorism, such as content that promotes terrorist acts, incites violence, or celebrates terrorist attacks.
               </p> 
               <p>
               5. SENSITIVE EVENTS - No user should post any content that that lack reasonable sensitivity towards or capitalize on a natural disaster, atrocity, health crisis, conflict, death, or other tragic event.
               </p> 
               <p>
               6. CYBER BULLYING AND HARASSMENT - No user should post any content that contain or facilitate threats, harassment, or bullying.
               </p> 
               <p>
               6. DANGEROUS PRODUCTS - No user should post any content that facilitate the sale of explosives, firearms, ammunition, or certain firearms accessories.
               </p> 
               <p>
               7. MARIJUANA - No user should post any content that facilitate the sale of marijuana or marijuana products, regardless of legality.
               </p> 
               <p>
               8. INTELLECTUAL PROPERTY -  No user should post any content on the Platform which is not original and is copied from an individual/organisation who owns the intellectual property rights in such content/ works is not allowed. Any content which violates the intellectual property rights of third parties will be taken down and strict action will be taken against users who are repeat defaulters.
               </p> 
               <p>
               9. TOBACCO AND ALCOHOL - No user should post any content that  facilitate the sale of tobacco (including e-cigarettes and vape pens) or encourage the illegal or inappropriate use of alcohol or tobacco.
               </p> 
               <p>
                10. RELIGIOUSLY OFFENSIVE CONTENT - No user must publish anything which is hurtful to the sentiments of others, or which insults their
                 religion or customs and/or causes communal discord.
                 </p>
                 <p>
                  11. SEXUAL HARASSMENT AND NON-CONSENSUAL NUDITY - No user should make statements of a sexual nature which are intended
                   to be insulting, harassing, or degrading on the basis of gender, or which make any other person feel 
                   uncomfortable. Bandhoo does not condone sexual harassment on the platform, including, but not limited to, sexually
                    explicit remarks, or innuendoes directed at any specific person or persons, or the sharing of sexual
                     (or sexually suggestive) information or media in relation to a person without their consent or any content 
                     which may may invasive of a person's bodily privacy.
                  </p>
                  <p>
                    12. INVASION OF PRIVACY - No user should be disrespectful towards the privacy of others. You must refrain from 
                    publishing sharing, or encouraging the publication of, any personal information of another personor which is invasive of their privacy.
                     </p>
                     <p>
                      13. IDENTITY THEFT AND IMPERSONATION - No user should  impersonate or pose as another person, brand, or organization to deceives or misleads 
                      users about the origin of the content.
                      </p>
                     <p>
                  14. MISINFORMATION AND FAKE NEWS - No user knowingly post or share information, which is unverified or false or
                   misleading, but may be perceived as factual. Users must not upload morphed or manipulated media, 
                   including photoshopped pictures or doctored videos. We also do not permit any content which may interfere with the results of political
                    elections. You must, to the extent possible, make sure that the content that you post on Bandhoo is authentic and
                     from a reliable and verifiable source.
                   </p>
                     <p>
                If any user post such content then Bandhoo technoogy has all the rights to delete such content without consent of user. If user post
               such content  repetitively, then his user account may be blocked or deleted without prior information.
            </p>


            <h4>Intellectual property violations</h4>
            <p>Bandhoo does not undertake any obligation to monitor user generated content, except to the extent required by law. Bandhoo
               is only an intermediary that primarily enables online interaction between two or more users and allows them to upload, create,
                share, disseminate, modify or access information using Bandhoo platform.</p>
                <p>While Bandhoo supports valid and legitimate claims of intellectual property ownership, it cannot and does not adjudicate any claims. 
                  In the first instance, parties must resolve any disputes relating to intellectual property amongst themselves or through legal process,
                   before reporting to Bandhoo  If you believe that someone is violating your or anyone else's intellectual property, you may report it
                   at feedback@bandhoo.in</p>
                <p>The contents of such report  and the reporter's email address will be provided to the person who posted the
                   contested content along with a request to respond to the claim within 48 hours. Parties are encouraged to directly interact and resolve any issues.
                   If no response is received within the specified time, or either the report or the response are unsatisfactory, then Bandhoo has sole discretion to 
                     take such action as it deems fit. Please note that Bandhoo is acting on a best efforts basis and accepts no responsibility for any actions taken by it. </p>

            <h4>Child Safety</h4>
              <p>Bandhoo totally prohibits child sexual abuse and exploitation, including content or behavior that sexually exploits, abuses, or endangers children (CSAE).</p>
              <p>CSAE includes, for example, grooming a child for sexual exploitation, sextorting a child, trafficking of a child for sex, or otherwise sexually exploiting a child.</p>
              <p>1. Any post or message  by any user that can be potential case for child sexual abuse and exploitaion will be removed without any intimation, if it come into the knowledge of bandhoo. Bandhoo also reserve 
                the right to block or remove such users without any intimation.</p>
              <p>2. All users are advised not to endulge in any activity that may lead to CSAE.</p>
              <p>3. CSAM stands for child sexual abuse material. It is illegal and Bandhoo  prohibit using our platform to upload or share this content.
                 CSAM consists of any visual depiction, including but not limited to photos, videos, and computer-generated imagery, involving the use of a minor engaging in sexually explicit conduct. </p>
              <p> 4. Bandhoo reserves the right of removing CSAM, without intimation, if it get actual knowledge of it. </p> 
              <p> 5. Bandhoo also reserve the right to take action against the users who are found to be uploading or sharing CSAM. Information of such users may also be passed to Law enforcement agencies
                if asked by them and if information is available with bandhoo.</p>
              <p>6. Bandhoo app is committed to child safety and applicable child safety laws and regulations.</p>
              <p>7. Any information regarding CSAE or CSAM on Bandhoo platform may be shared through feedback@bandhoo.in also.</p>



            <h4>Terms and Reference -</h4>
            <p>
            When we say “Bandhoo” “our,” “we,” or “us,” we’re talking about this social chat application “Bandhoo”.
             This Privacy Policy (“Privacy Policy”) applies to all of our , services, features, software, and website
              (together, “Services”) unless specified otherwise. When we say “you”,”your”, it means the users who have
               installed/are installing and using our app “Bandhoo”. The following terms of service constitute a binding
                legal agreement between you and Hibuddy- 
            </p>
            <p>
                1. By accessing, downloading, installing the Application, You represent to Bandhoo that You are competent
                 to enter into a contract (i.e. You a major according to applicable law, of sound mind and not 
                 disqualified from entering into a contract under the applicable law) and You have read this Agreement, 
                 understand it and agree to be bound by its Terms and condition. 2. The Bandhoo Application and related 
                 services are currently made available to You by Bandhoo technoogy free of any charge. 3. Bandhoo reserve the right 
                 to suspend or withdraw this Application, at any time, without notice 4. The Application can also be 
                 downloaded from third party application stores including Google Play, Nokia Ovi Store and Apple App Store 
                 etc. You agree to comply with all third party terms and conditions and privacy policy, if any, applicable to
                  the use of the Application or related services. Bandhoo shall not be responsible for violation and
                   infringement of any third party right, terms and conditions in connection with access, download or 
                   installation of the Application by You. 5. You shall use the Application solely for Your personal and 
                   lawful use only; 6. You will not, nor allow third parties on Your behalf (i) to resell or charge others 
                   for use of the Application (ii) to duplicate, disassemble, decompile, transfer, exchange or translate 
                   the Application, create derivative works of the Application of any kind whatsoever or attempt to reverse
                    engineer, alter or modify any part of the Application 7. You will provide accurate information about 
                    Your mobile phone number,email and any other information required for use of the Application. 8. Your 
                    use of the Application or the site will comply with all applicable local, state, national or 
                    international laws (“Laws”) and will not violate any law, regulation, or contractual obligations.
                     9. You will not misuse the Application and acknowledge and understand that “misuse” includes, but is
                      not limited to using the Application in any manner that (a) violates any copyright, trademark, patent
                       or any intellectual property right, discloses a trade secret or confidential information in 
                       violation of a confidentiality or non-disclosure agreement; (b) unsolicited advertising and messages,
                        promotional materials, junk mail, spam etc; (c) is fraudulent, unlawful or contains or promotes 
                        defamatory or illegal information and activities, images, materials or descriptions; (d) defames,
                         harasses, stalks, threatens or otherwise violates the legal rights of others or is invasive of
                        another’s privacy rights; (e) that is vulgar, obscene, offensive, or contains pornography or nudity
                         or otherwise objectionable (f) that is racially or ethnically offensive and encourages any conduct
                        that would constitute a criminal offense or that gives rise to civil liability; (g) send or store
                        material containing software viruses, worms, or other harmful computer code, files, scripts or
                         programs; (h) attempt unauthorized access to or use of data, systems or networks, including any 
                         attempt to probe, scan or test the vulnerability of a system or network or to breach security or
                         authentication measures without express authorization of the owner of the system or network,
                         (i) unauthorized attempt to monitor data or traffic on any network or system without express 
                         authorization of the owner of the system or network, (j) interference with service to any user,
                         host or network including, without limitation, mail bombing, flooding, deliberate attempts to
                        overload a system and broadcast attacks, (k) send harassing or threatening transmissions, (l)
                         probe for means of gaining unauthorized access to computers or networks, (m) restrict or inhibit
                         any other person from using this Application and or Site; and (vi) You will not impersonate any 
                         person or entity, or falsely state or otherwise misrepresent Yourself, Your age or Your 
                         affiliation with any person or entity. 10. You shall be solely responsible for all contents that 
                         You upload, transmit, share or display through the Application including but not limited to any
                         photos, profiles (including Your name and image), messages, information, text, video, music, 
                        third party links and automatic submission of your status. Hibuddy does not guarantee as to the
                         validity, accuracy or legal status or confidentiality with respect to any Contents and You shall be
                         solely responsible and assume all risks for any consequences of uploading, posting, transfer or
                         disclosure of the Contents. 11. Bandhoo reserves the right at any time and from time to time to
                        interrupt, restrict (without cause and without notice to You), modify or discontinue, temporarily 
                        or permanently, Application (or any part thereof) with or without any notice. 12. You acknowledge 
                        and agree that the Application and all trademarks, service marks and trade names and other intellectual property 
                        rights associated therewith are, and shall remain, the property of Bandhoo and its licensors, as applicable.

                        </p>
                        <p>
                         13. Disclaimer- YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, 
                         YOUR USE OF THE APPLICATION IS AT YOUR SOLE RISK AND DISCRETION. THE APPLICATION IS LICENSED AS-IS, WITH ALL 
                         FAULTS, AND AS AVAILABLE. YOU BEAR THE RISK OF USING IT. Bandhoo MAKES NO WARRANTY AGAINST INTERFERENCE OF YOUR 
                         ENJOYMENT OF THE APPLICATION AND RELATED SERVICES; FOR LOSSES OR DAMAGES INCLUDING ANY PERSONAL INJURY ARISING 
                         FROM OR IN ANY WAY RELATED TO YOUR ACCESS OR USE OF THE APPLICATION, USE OF ANY CONTENT POSTED, TRANSMITTED, OR
                          OTHERWISE MADE AVAILABLE VIA THE APPLICATION OR FOLLOWING A FAILURE, SUSPENSION OR WITHDRAWAL OF ALL OR PART OF
                    THE APPLICATION AT ANY TIME; ANY BUGS, VIRUSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR
                     APPLICATION OR RELATED SERVICES, THAT THE APPLICATION WILL BE FUNCTIONAL, UNINTERRUPTED, ERROR-FREE OR 
                     BUG-FREE OR MEET YOUR REQUIREMENTS; REGARDING THE SECURITY, RELIABILITY OR TIMELINESS OF THE APPLICATION;
                     THAT ANY ERRORS, BUGS OR FAILURES IN THE APPLICATION WILL BE CORRECTED. ACCORDINGLY, YOU EXPRESSLY AGREE 
                     THAT USE OF APPLICATION AND RELATED SERVICES IS PURELY VOLUNTARY ON YOUR PART, AT YOUR OWN RISK. 

                     </p>
                        <p>
                     14. We may in our sole discretion amend this Agreement from time to time without any prior notice. 
                     15. This Agreement commences on the date You accept the Terms of this Agreement. Bandhoo may terminate or 
                     suspend use of the Application or the Site at any time in its discretion, without notice to You. Upon any 
                     termination for any reason, Bandhoo shall have no liability to You and no further obligations under this 
                     Agreement. 16. You acknowledge that You have read this Agreement, understand it and agree to be bound by 
                     its terms and conditions. 17. This Agreement shall be governed by the laws of India. You irrevocably consent
                    to the exclusive jurisdiction of courts in New Delhi, India for all disputes arising out of or relating to 
                    this Agreement. 
            </p>
            <p>
            Write to us – bandhoo_feedback@yahoo.com or bandhoo.official@yahoo.com 
            </p>
            </div>

        )
    }
}
export default Privacy_policy;
